<template>
  <div>
    <div class="line"></div>
    <div
      class="details"
      v-for="(item, index) in regulatuons"
      :key="index"
      @click="handleDetail(item)"
    >
      <div class="fl-content">{{ item.title }}</div>
      <div class="time">
        {{ item.publishTime }}
      </div>
    </div>
    <!-- 分页 -->
    <el-pagination
      class="paging"
      background
      layout="prev, pager, next"
      :total="total"
      @current-change="handlePageChange"
      @prev-click="handlePageChange"
      @next-click="handlePageChange"
      :current-page.sync = "pageNo"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "",
  data() {
    return {
      //法律法规
      regulatuons: [],
      pageNo: 1,
      pageSize: 10,
      condition: "documentClassify:S_IN0||",
      total: 0,
    };
  },
  created() {
    this.pageNo = parseInt(this.$route.params.page) || 1
  },
  mounted() {
    this.handleGetList();
  },
  watch: {
    '$route.params.page'(val) {
      this.pageNo = val
      this.handleGetList()
    }
  },
  methods: {
    ...mapActions(["setRegulationDetail"]),
    // 列表接口
    handleGetList() {
      // debugger
      this.$axios({
        method: "post",
        url: "/laf/laf/lafLegaldocument/tableList.ajax",
        data: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          condition: this.condition,
          _entityName: "org.aweto.laf.entity.LafLegaldocument",
          orderCondition: "desc",
          orderField: "publishTime",
        },
      })
        .then((res) => {
          this.total = res.totalRows;
          this.regulatuons = res.rows;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
       // 当前页面
    handlePageChange(e) {
      this.$router.push({
        name: 'regulations',
        params: {
          page: e
        }
      })
    },
    // 跳转详情
    handleDetail(data) {
      const pkValue = data.row_pkValue
      window.open(`./#/regulationDetail?pkValue=${pkValue}`, '_blank')
      // console.log(data)
      // 将详情赋值给vuex中
      // this.setRegulationDetail(e);
      // this.$router.push({
      //   name: "regulationDetail",
      // });
    },
  },
};
</script>

<style lang='scss' scoped>
.fl-content {
  width: 75%;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.time {
  float: right;
  font-size: 12px;
  color: rgb(187, 187, 187);
}
.contentItem {
  height: 60px;
  margin-top: 20px;
  text-decoration: none;
}
.line {
  width: 100%;
  height: 1px;
  border-top: 1px solid #e5e5e5;
  margin: 0 auto;
}
.details {
  height: 80px;
  border-bottom: 1px solid #e5e5e5;
  line-height: 80px;
  font-size: 16px;
  color: #333333;
  cursor: pointer;
  padding: 0 20px;
  box-sizing: border-box;
  &:hover {
    background: #f8f8f8;
  }
}
// .details :nth-child(2) {
//   float: right;
//   margin-right: 40px;
// }
// .links {
//   border-bottom: 1px solid #e5e5e5;
// }

.lafPage {
  text-align: center;
  margin-top: 50px;
}
::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #cd242a;
}
::v-deep .paging {
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}
</style>